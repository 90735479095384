import React from 'react'
import * as common from '../common'
import { ToastContainer } from 'react-toastify'

const DesktopLayout = ({children}) => {
  return (
    <div>
      <common.Header/>
      {/* <common.floatingComponent /> */}
      {
        children
      }
      <ToastContainer className="Toastify__toast-container--top-center" />
      <common.Footer/>
      
    </div>
  )
}

export default DesktopLayout
